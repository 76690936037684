.html-renderer {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: match-parent;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.html-renderer p {
  margin: 0;
  padding: 0;
}

.html-renderer ol,
.html-renderer ul {
  margin: 0;
  padding-left: 1.5rem;
}

.html-renderer .ql-align-center {
  text-align: center;
}

.html-renderer .ql-align-right {
  text-align: right;
}
