h1 {
  color: deepskyblue;
  text-align: center;
}
#title {
  padding: 0.2%;
  color: black;
}
.SubTitle {
  font-size: small;
}
#s_title {
  font-size: larger;
}
#path_table {
  width: 100%;
  table-layout: fixed;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
a {
  color: deepskyblue;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: center;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
}
.dep {
  border: 1px solid rgb(255, 255, 255);
  margin: 5px;
  padding: 5px;
}
.dep-name {
  font-weight: bold;
}

table td {
  padding: 12px 15px;
  background-color: rgba(212, 212, 212, 0.329);
}

table td:hover {
  background-color: rgb(255, 255, 255);
}

table th {
  padding-top: 12px;
  padding-bottom: 15px;
  text-align: center;
  background-color: rgb(0, 0, 0);
  color: rgba(158, 158, 158, 0.493);
}

#enterButton {
  background-color: rgb(179, 179, 179);
  height: 40px;
  width: 300px;
  color: #fff;
}

#buttonInput {
  background-color: rgb(112, 112, 112);
  color: #fff;
}

#confirmButtonInput {
  background-color: deepskyblue;
  color: #fff;
}

#warnButtonInput {
  background-color: orange;
  color: #fff;
}

#dangerButtonInput {
  background-color: red;
  color: #fff;
}

.tutorial {
  position: relative;
  padding: 7%;
  padding-top: 3%;
  text-align: center;
}

#SmallText {
  color: rgb(117, 39, 39);
}

.introduction {
  float: centre;
  padding: 4%;
  text-align: left;
  background-color: black;
  color: antiquewhite;
}

.content {
  float: centre;
  padding: 4%;
  margin-top: 0.1%;
  text-align: left;
  background-color: black;
  color: antiquewhite;
}

#list {
  float: centre;
  padding: 1%;
  text-align: left;
  border-left: 30px;
}

.videoPlayer {
  height: 100%;
  width: 100%;
  min-height: 225px;
  padding-left: 23%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.button {
  background-color: #04aa6d;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 40px 40px;
  padding: 40px;
}

.button1 {
  border-radius: 2px;
}
.button2 {
  border-radius: 4px;
}
.button3 {
  border-radius: 8px;
}
.button4 {
  border-radius: 12px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  background-color: #2196f3;
  padding: 50px;
}

#message {
  padding-right: 12%;
  padding-top: 12%;
}

#manual {
  padding-left: 12%;
  padding-top: 12%;
}

#overview {
  padding-left: 12%;
  padding-bottom: 5%;
}

#user {
  padding-right: 12%;
  padding-bottom: 5%;
}

.react-flow__controls {
  position: absolute;
}
