.react-flow__handle {
  background: transparent !important;
  border-radius: 0;
  border: 0;
  height: 1.9rem;
  right: 0;
  width: 1.9rem;
}

.react-flow__handle::after {
  background-color: white;
  border-radius: 100%;
  border: 1px solid black;
  content: '';
  display: block;
  height: 0.6rem;
  position: absolute;
  top: 50%;
  width: 0.6rem;
}

.react-flow__handle-right {
  transform: translate(10%, -50%);
}

.react-flow__handle-right::after {
  transform: translate(170%, -50%) !important;
}

.react-flow__handle-left {
  transform: translate(0%, -50%);
}

.react-flow__handle-left::after {
  transform: translate(-10%, -50%) !important;
}

.react-flow__handle-top {
  /* transform: translate(0%, 0%) */
}

.react-flow__handle-top::after {
  transform: translate(80%, -140%) !important;
}

.react-flow__handle-bottom {
  /* transform: translate(50%, -50%) */
}

.react-flow__handle-bottom::after {
  transform: translate(80%, 40%) !important;
}

div[data-handleid='yes-handle']::after {
  background-color: #09d85f;
}

div[data-handleid='no-handle']::after {
  background-color: #cf0e0e;
}

.react-flow__edge-text {
  font-size: 0.75rem;
}
