body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

tooltip {
  color: #c4564f;
}

.ql-insert-tooltip {
  color: #444;
}

.ql-container {
  height: 30vh;
}

.ql-insert-tooltip:hover {
  color: #06c;
}

/** Custom react-flow styling */
.react-flow__edge > .react-flow__edge-path {
  stroke: #bdbdbd;
  stroke-width: 3;
}

.react-flow__edge.selected > .react-flow__edge-path {
  stroke: #212121;

  /* NOTE: A better way to interact with edge arrows and change their style will be implemented in the following pull request
           https://github.com/wbkd/react-flow/pull/1555 which has not been merged at the time of writing */
}

.react-flow__controls {
  bottom: 0.5rem;
  left: 10px;
  position: fixed;
  z-index: 5;
}

.react-flow__minimap{
  bottom: 0.5rem;
  position: fixed;
  z-index: 5;
}
footer.page-footer {
  color: #fff;
}

.font-small {
  font-size: 0.9rem;
}

.btn {
  padding: 0.5rem 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-bottom: 1.5rem !important;
  padding-left: 0.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 0.5rem !important;
}

.blue {
  background-color: #00bfff !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}
