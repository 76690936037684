nav {
  overflow-x: auto;
  min-height: 5vh;
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: #00bfff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

nav li {
  background-color: #00bfff;
}

nav a {
  color: white;
  text-decoration: none;
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1vh;
}

.logoNav {
  height: 4vh;
  width: 4vh;
  margin-left: 0.1em;
  margin-top: 0.1em;
}

.homeLogo {
  height: 12em;
  width: 10em;
}

.home {
  padding: 1rem;
}
